import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery(
    $paginatePostsPage: Boolean!
    $skip: Int
    $limit: Int
    $imageQuality: Int!
  ) {
    featuredPosts: allDatoCmsArticle(
        filter: { website: {elemMatch: {name: {eq: "oam"}}} }
      sort: { fields: [publishedAt], order: DESC }
      limit: 10
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailFeatured
      }
    }

    recentPosts: allDatoCmsArticle(
        filter: { website: {elemMatch: {name: {eq: "oam"}}} }
      sort: { fields: [publishedAt], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    posts: allDatoCmsArticle(
        filter: { website: {elemMatch: {name: {eq: "oam"}}} }
      sort: { fields: [publishedAt], order: DESC }
    ) @skip(if: $paginatePostsPage) {
      group(field: category___title, limit: 10) {
        categoryName: fieldValue
        nodes {
          ...ArticlePreview
          ...ArticleThumbnailRegular
        }
      }
    }

    paginatedPosts: allDatoCmsArticle(
        filter: { website: {elemMatch: {name: {eq: "oam"}}} }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
